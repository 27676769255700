import React from "react";

const Background = () => {

    return (
        <div className="container-fluid background-container">
            <h1>Background</h1>

            <div className="background-content">

                <div className="row">
                    <div className="col-md-6">
                        <p>
                            I graduated the University of Leeds in 2021 with a Bachelors Degree in Computer Science. During my studies I responded to the Covid-19 pandemic by becoming a <a target="_blank" href="https://www.instagram.com/p/B-2ORLIhIUE-KG2OLRxgipne-xp5MUKreIhS7E0/">Covid-19 Testing Supervisor in Leeds</a>. I was responsible for the Assisted & Lateral Flow testing lanes, personally testing 1000s of people and training new testers to join the workforce.
                        </p>
                    </div>
                    <div className="col-md-6">
                        <p>
                            These days I am a software developer specialising in full stack web applications. I relocated to Vancouver, BC in June 2022 to pursue my love of travel. When I'm not coding I like to get out the house to <a target="_blank" href="https://www.instagram.com/pegasus_drone/">fly my drone</a> or discover some cool places.
                        </p>
                        <p>
                            Check out my <a target="_blank" href="https://linkedin.com/in/jakebutterfield">LinkedIn</a> for more info on my professional history.
                        </p>
                    </div>
                </div>

            </div>
        </div >
    )

}

export default Background