export const projects = [
    {
        title: 'iFynd',
        img: '/img/projects/ifynd.png',
        tech: ['PHP/Laravel', 'React/React Native', 'Semantic UI'],
        type: 'Full Stack Development',
        content: 'I work at iFynd as the Lead Developer. During my time at iFynd, I was responsible for the MVP, making it as user-friendly and intuitive as possible. This included designing & developing live prototypes to test certain features on the fly as we had tight deadlines at each step.',
        links: {
            web: 'https://ifynd.com/',
        },
    },
    {
        title: 'Smooth Dash',
        img: '/img/projects/smoothdash.png',
        tech: ['React', 'Chrome Web Store'],
        type: 'Full Stack Development',
        content: "Smooth Dash is a simple 'New Tab' dashboard. Upgrade your current dashboard by displaying the local weather, being productive with the to-do lists and customising your background. This was made using React & was published onto the Google Chrome Web Store.",
        links: {
            chrome: 'https://chrome.google.com/webstore/detail/ghcinoibagkpdkhngbplilpcmonndckk/preview',
            web: 'https://jakebutterfield.co.uk/smoothdash',
            git: 'https://gitlab.com/JakeButterfield99/chrome-dashboard',
        },
    },
    {
        title: 'Minekea',
        img: '/img/projects/minekea.png',
        tech: ['PHP/Laravel', 'React', 'ThreeJS', 'Java'],
        type: 'Full Stack & Game Development',
        content: 'Minekea is a WIP Minecraft build sharing platform which aims to ease to sharing & reconstructing of users favourite builds. This includes a breakdown (blueprint) of the building along with a 3D render. This platform also includes social communication between users.',
        links: {},
    },
    {
        title: 'Nitopa African Adventures',
        img: '/img/projects/nitopa.png',
        tech: ['HTML/CSS', 'Squarespace'],
        type: 'Front End Development',
        content: 'Nitopa African Adventures provides Safaris and Hikes in Tanzania & Zanzibar. I worked with Nixon on the front end development of the site & booking system using Squarespace.',
        links: {
            web: 'https://www.nitopaafricanadventures.com/',
        },
    },
    {
        title: 'Contact Tracing App',
        img: '/img/projects/tracing.png',
        tech: ['Python/Django', 'Swift'],
        type: 'Full Stack & iOS Development',
        content: 'For my final year project in the University of Leeds I focused on the Covid-19 Pandemic that was happening at the time. I developed my own Virus Contact Tracing App for businesses that featured a web panel for a covid admin to review any breaches in social distancing. I identified the requirements by interviewing several companies about their Covid-19 policies and positive-case processes.',
        links: {
            git: 'https://gitlab.com/comp3921-final-project',
        },
    },
    {
        title: 'SantosRP.life',
        img: '/img/projects/santosrp.png',
        tech: ['PHP/Laravel', 'Lua', 'Garrys Mod'],
        type: 'Back-End & Game Development',
        content: 'SantosRP.life was a Garrys Mod server based on the original santosrp gamemode. For this project I designed and developed in-game features including bank & store robbery systems, business overhauls, criminal empires. I also developed the full stack range of databases, custom forums & web panels.',
        links: {
            git: 'https://gitlab.com/JakeButterfield99/santosrp.life',
        },
    },
]