import React, { useRef, useState } from "react";
import emailjs from '@emailjs/browser';

const Contact = () => {

    const form = useRef()
    const [showForm, setShowForm] = useState(true);

    const submitForm = (e) => {
        e.preventDefault();

        emailjs.sendForm(
            process.env.REACT_APP_EMAIL_JS_SERVICE,
            process.env.REACT_APP_EMAIL_JS_TEMPLATE,
            form.current,
            process.env.REACT_APP_EMAIL_JS_KEY
        )
            .then((result) => {
                setShowForm(false);
            }, (error) => {
                console.log('no', error.text);
            });

    }


    return (
        <div className="container contact-container">

            <h1>Contact Me</h1>

            {showForm ?
                <form ref={form} onSubmit={submitForm}>
                    <div className="row">
                        <div className="col">
                            <label>Name:</label>
                            <input name="name" type="text" className="form-control form-control-lg" placeholder="John Smith" />
                        </div>
                        <div className="col">
                            <label>Email Address:</label>
                            <input name="email_address" type="text" className="form-control form-control-lg" placeholder="john@smith.com" />
                        </div>
                    </div>
                    <label>Message:</label>
                    <textarea name="message" placeholder="Dear Jake..." className="form-control form-control-lg" />

                    <button type="submit" className="btn">Send</button>

                </form>
                :
                <h3>Thanks! I'll be in touch shortly.</h3>
            }



        </div>
    )
}

export default Contact