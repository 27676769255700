import React from "react";
import ReactGA from 'react-ga';

import Project from "../components/Project";

import { projects } from "../config";

const Projects = () => {

    ReactGA.pageview(window.location.pathname + window.location.search);

    return (
        <div className="projects">

            {projects.map(function (val, idx) {
                return (
                    <Project
                        idx={idx}
                        title={val.title}
                        img={val.img}
                        type={val.type}
                        content={val.content}
                        tech={val.tech}
                        links={val.links}
                    />
                )
            })}
        </div>
    );
}

export default Projects