import React from "react";
import ReactGA from 'react-ga';

import Header from "../components/Header"
import Background from "../components/Background"
import Contact from "../components/Contact"
import InstaFeed from "../components/InstaFeed"

const HomePage = () => {

    ReactGA.pageview(window.location.pathname + window.location.search);

    return (
        <div>
            <Header />
            <Background />
            <Contact />
            <InstaFeed />
        </div>
    )
}

export default HomePage