import React from "react";
import { Link } from "react-router-dom";

const Navigation = () => {

    return (
        <div className="container">
            <nav className="navbar">
                <div className="navbar-header">
                    <ul className='navbar-nav'>
                        <li>
                            <Link className='nav-link' to="/">
                                <img src='/img/logo.png' />
                            </Link>
                        </li>
                    </ul>
                </div>
                <div className="navbar-content">
                    <ul className="navbar-nav navbar-right">
                        <li className='nav-item'>
                            <Link className='nav-link' to="/projects">Projects</Link>
                        </li>
                    </ul>
                </div>
            </nav>
        </div>
    )

}

export default Navigation