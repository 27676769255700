import React from "react";

const Project = (props) => {

    const colour = props.idx % 2 ? 'white' : 'purple';

    return (
        <div key={props.idx} className={`project p-${colour}`}>
            <div className="img">
                <img src={props.img} />
                <div className="links">
                    {props.links.web &&
                        <a target='_blank' href={props.links.web}>
                            <button>
                                Website
                            </button>
                        </a>
                    }
                    {props.links.git &&
                        <a target='_blank' href={props.links.git}>
                            <button>
                                GitLab
                            </button>
                        </a>
                    }
                    {props.links.chrome &&
                        <a target='_blank' href={props.links.chrome}>
                            <button>
                                Chrome Store
                            </button>
                        </a>
                    }
                    {Object.keys(props.links).length == 0 &&
                        <button>
                            Please contact for more info
                        </button>
                    }
                </div>
            </div>
            <div className="content">
                <h1>{props.title}</h1>
                <h2>{props.type}</h2>
                <p className="para">{props.content}</p>
                <div className="tech">
                    {props.tech.map(function (val) {
                        return <p>{val}</p>
                    })}
                </div>
            </div>
        </div>
    )
}
export default Project