import React from "react";
import InstagramFeed from "react-ig-feed";

import 'react-ig-feed/dist/index.css'

const InstaFeed = () => {

    return (
        <div className="container-fluid insta-container">
            <InstagramFeed
                token={process.env.REACT_APP_INSTAGRAM_KEY}
                counter="6"
            />
        </div>
    )

}

export default InstaFeed;