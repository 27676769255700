import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import ReactGA from 'react-ga';

import Navigation from './components/Navigation';
import HomePage from './pages/HomePage';
import Projects from './pages/Projects';

import 'react-ig-feed/dist/index.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import './css/style.css';
import './css/home.css';
import './css/projects.css';

function App() {

  ReactGA.initialize('G-QTZLRDN09K');

  useEffect(() => {
    document.title = "Jake Butterfield";
  })

  return (
    <Router>

      <Navigation />

      <Routes>
        <Route path='/' element={<HomePage />} />
        <Route path='/projects' element={<Projects />} />
      </Routes>

    </Router>
  );
}

export default App;
