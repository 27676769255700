import React from "react";

const Header = () => {

    return (
        <div className="container">
            <header className="row d-flex align-items-center">

                <div className="col-md-6 jake-img">
                    <img className="shadow-lg big-img" src="/img/jake.png" />


                </div>

                <div className="col-md-6 jake-content">
                    <h1>Hi, I'm Jake.</h1>
                    <p>I'm a Full Stack Developer working with Laravel & React.</p>
                    <p>I am currently based in Vancouver, BC and open to hybrid or remote working opportunities.</p>

                    <div className="icon-shelf">
                        <a target="_blank" href="https://www.instagram.com/jakebutterfield_dev/">
                            <img src="/img/icons/instagram.png" />
                        </a>
                        <a target="_blank" href="https://www.linkedin.com/in/jakebutterfield/">
                            <img src="/img/icons/linkedin.png" />
                        </a>
                        <a target="_blank" href="https://gitlab.com/JakeButterfield99">
                            <img src="/img/icons/gitlab.png" />
                        </a>
                    </div>
                </div>

            </header>
        </div>
    )

}

export default Header